import React,  { useEffect }  from 'react';  
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';  
import Home from './Home';  
import NotFound from './NotFound';  
import './App.css';  
  
function App() {  
  useEffect(() => {  
    // 컴포넌트가 마운트될 때 제목을 설정합니다.  
    document.title = "Nekomata";  
  });  

  return (  
    <Router>  
      <div style={{ backgroundColor: '#000000', color: '#800000', textAlign: 'center', fontFamily: 'Arial, sans-serif', minHeight: '100vh' }}>  
        <Routes>  
          <Route path="/" element={<Home />} />  
          <Route path="/404" element={<NotFound />} />  
        </Routes>  
      </div>  
    </Router>  
  );  
}  
  
export default App;  