import React from 'react';  
import './App.css';  

const TwitchEmbed = ({ channel }) => {  
return (  
    <iframe  
    src={`https://player.twitch.tv/?channel=${channel}&parent=nekomata.io&autoplay=false`}  
    height="480"  
    width="720"  
    frameBorder="0"  
    scrolling="no"  
    allowFullScreen={true}  
    ></iframe>  
);  
};  

const VimeoEmbed = () => {  
  const containerStyle = {  
    width: '75%', 
    margin: '0 auto',  
    position: 'relative',  
    paddingTop: '42.1875%'
  };  
  
  const iframeStyle = {  
    position: 'absolute',  
    top: 0,  
    left: 0,  
    width: '100%',  
    height: '100%'  
  };  
  
  return (  
    <div style={containerStyle}>  
      <iframe  
        src="https://vimeo.com/event/4775959/embed/interaction"  
        frameBorder="0"  
        allow="autoplay; fullscreen; picture-in-picture"  
        allowFullScreen  
        style={iframeStyle}  
      ></iframe>  
    </div>  
  );  
};  

const CopyToClipboard = () => {  
    const textToCopy = "Loading...Loading...Loading...Loading...Loading...";  
    
    const handleCopy = () => {  
      navigator.clipboard.writeText(textToCopy)  
        .then(() => {  
          alert('The text has been copied to the clipboard!');  
        })  
        .catch(err => {  
          console.error('copy failed.', err);  
        });  
    };  
    
    return (  
    <div>  
    <span   
        onClick={handleCopy}   
        style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
    >  
        {"CA(Moonshot): " + textToCopy} {}  
    </span>  
    </div>  
);  
  };  

function Home() {  
  return (  
    <div className="App">  
      <div className="content">  
        <p className="centered-text">  
          <b>  
            <font size="6">Nekomata Updating...</font>  
           
          </b>  
        </p>  
        <hr />  
       </div>
      
      <small>  
      <p>  
      </p>  
      </small>  
      <footer className="footer">  
 
        <p>  
          © 2024 <b>Nekomata</b>  
        </p>  
      </footer>  
    </div>  
  );  
}  
  
export default Home;  